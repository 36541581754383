<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <suy-table :reload="loadData" v-model:columns="columns" >
    <template #search>
      <a-form ref="formQueryRef" :model="searchForm">
        <a-row>
          <a-col :span="4">
            <a-form-item>
              <a-cascader :options="$store.state.app.cityDict" :changeOnSelect="true" :show-search="{ filter }" @change="setEndAddress" placeholder="请选择城市" />
            </a-form-item>
          </a-col>
          <a-divider type="vertical" />
          <a-col :span="4">
            <a-form-item>
              <a-select ref="select" v-model:value="searchForm.orgId" placeholder="归属区域" allowClear>
                <a-select-option v-for="item in $store.state.app.orgList " :key="item.id">{{item.name}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-divider type="vertical" />
          <a-col :span="4">
            <a-button type="primary" @click="onSearch">
              <template #icon>
                <SearchOutlined />
              </template>查询
            </a-button>
          </a-col>
        </a-row>
      </a-form>
    </template>
    <!-- <template #toolbarLeft>
      <a-form-item label="总数">
        <a-tag color="red">{{totalNum}}条</a-tag>
      </a-form-item>
    </template> -->
    <template #toolbarRight>
      <!-- <a-button @click="exportData">
        导出Excel
      </a-button> -->
    </template>
    <a-table :size="small" :columns="columns" :scroll="{ x:1500, y: 520}" :row-key="record => record.id" :data-source="listData" :loading="loading" :pagination="pagination" :rowKey="(record,index)=>{return index}" @change="handleChange">
      <template #nowCity="{ record }">
        <span class=" total_font_size">
          {{record.nowCity}}
        </span>
      </template>
      <template #orgName="{ record }">
        <span class=" total_font_size">
          <span>{{record.orgName}}</span>
        </span>
      </template>
      <template #total="{ record }">
        <span class="total total_font_size" @click="onDetail('',record)">
          <span v-if="record.total==null">0</span>
          <span v-else>{{record.total}}</span>
        </span>
      </template>
      <template #orderTotal="{ record }">
        <span class="ototal total_font_size" @click="onDetail(1,record)">
          <span v-if="record.orderTotal==null">0</span>
          <span v-else>{{record.orderTotal}}</span>
        </span>
      </template>
      <template #pushTotal="{ record }">
        <span class="ptotal total_font_size" @click="onDetail(2,record)">
          <span v-if="record.pushTotal==null">0</span>
          <span v-else>{{record.pushTotal}}</span>
        </span>
      </template>
      <template #transTotal="{ record }">
        <span class="ttotal total_font_size" @click="onDetail(3,record)">
          <span v-if="record.transTotal==null">0</span>
          <span v-else>{{record.transTotal}}</span>
        </span>
      </template>
      <template #doneTotal="{ record }">
        <span class="dtotal total_font_size" @click="onDetail(9,record)">
          <span v-if="record.doneTotal==null">0</span>
          <span v-else>{{record.doneTotal}}</span>
        </span>
      </template>
    </a-table>
  </suy-table>
  <a-drawer title="库存管理" :width="drawerwid" :closable="true" :visible="visible" :maskClosable="true" @close="onSwitch(false)" v-if="visible" :zIndex="1000">
    <stockDetail ref="stockDetail" :cityDetail="cityDetail" :type="type" :cityIdDetail="cityIdDetail"></stockDetail>
  </a-drawer>

</template>
<script>
import { stockInList, stockInPageExport } from '@/api/dataCenter/stock'
// import { getOrgList } from '@/api/global'
import { toRefs, ref, reactive, onMounted } from 'vue'
import { SearchOutlined } from '@ant-design/icons-vue'
import SuyTable from '@/components/SuyTable'
import stockDetail from './detail'
export default {

  components: {
    SearchOutlined,
    SuyTable,
    stockDetail
  },
  setup () {
    const state = reactive({
      small: 'default ',
      orgList: [],
      drawerwid: '90%',
      formRef: null,
      stockDetail: ref(null),
      visible: false,
      loading: false,
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      type: 0,
      cityDetail: '',
      cityIdDetail: 0,
      searchForm: {
        type: 1,
        rowNum: 1
      },

      totalNum: 0,
      form: {
        quota: '',
        orgId: '',
        num: 0,
        type: 1
      },
      listData: [],
      columns: [

        {
          title: '归属区域',
          dataIndex: 'orgName',
          key: 'orgName',
          slots: {
            customRender: 'orgName'
          }
        },
        {
          title: '城市',
          dataIndex: 'nowCity',
          key: 'nowCity',
          slots: {
            customRender: 'nowCity'
          }
        },
        {
          title: '总数',
          dataIndex: 'total',
          key: 'total',
          slots: {
            customRender: 'total'
          }
        },
        {
          title: '已接单台次',
          dataIndex: 'orderTotal',
          key: 'orderTotal',
          ellipsis: true,
          slots: {
            customRender: 'orderTotal'
          }
        },
        {
          title: '已推送台次',
          dataIndex: 'pushTotal',
          key: 'pushTotal',
          ellipsis: true,
          slots: {
            customRender: 'pushTotal'
          }
        },
        {
          title: '运输中台次',
          dataIndex: 'transTotal',
          key: 'transTotal',
          ellipsis: true,
          slots: {
            customRender: 'transTotal'
          }
        },
        {
          title: '完成台次',
          dataIndex: 'doneTotal',
          key: 'doneTotal',
          ellipsis: true,
          slots: {
            customRender: 'doneTotal'
          }
        }

      ]
    })
    const onSearch = () => {
      state.pagination.current = 1
      loadData()
    }
    const loadData = async () => {
      state.loading = true
      stockInList({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.size
      }).then((res) => {
        if (res.code === 10000) {
          state.listData = res.data
        }
      }).finally(() => {
        state.loading = false
      })
    }
    onMounted(loadData)
    const handleChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }

    const exportData = () => {
      stockInPageExport({ ...state.searchForm }, '库存统计-明细').then(res => {
      })
    }
    // getOrgList({ orgType: 1 }).then(res => { state.orgList = res.data })

    const setEndAddress = (value, selectedOptions) => {
      state.searchForm.provinceId = value[0]
      state.searchForm.cityId = value[1]
    }
    const onSwitch = (parameter) => {
      state.visible = parameter
    }
    const onDetail = (type, record) => {
      state.type = type
      state.cityDetail = record.nowCity
      state.cityIdDetail = record.cityId * 1
      onSwitch(true)
    }
    return {
      onDetail,
      onSwitch,
      onSearch,
      ...toRefs(state),
      loadData,
      setEndAddress,
      exportData,
      handleChange
    }
  }
}
</script>
