<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <suy-table :reload="loadData" v-model:columns="columns" >
    <template #search>
      <a-form ref="formQueryRef" :model="searchForm">
        <a-row>
          <a-col :span="4">
            <a-form-item>
              <a-cascader :options="$store.state.app.cityDict" :changeOnSelect="true" :show-search="{ filter }" @change="setEndAddress" :placeholder="placeholder" />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="车辆状态">
              <a-select v-model:value="searchForm.vehicleTransStatus" class="vehiclenum_orgId" option-filter-prop="children" show-search :not-found-content="null" allowClear>
                <a-select-option :value=0>全部</a-select-option>
                <a-select-option :value=1>已接单</a-select-option>
                <a-select-option :value=2>已推送</a-select-option>
                <a-select-option :value=3>运输中</a-select-option>
                <a-select-option :value=9>完成</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="时间">
              <a-space>
                <a-date-picker v-model:value="searchForm.startTime" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" />
                ~
                <a-date-picker v-model:value="searchForm.endTime" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" />
              </a-space>
            </a-form-item>
          </a-col>
          <a-divider type="vertical" />
          <a-col :span="4">
            <a-button type="primary" @click="searchBtn">
              <template #icon>
                <SearchOutlined />
              </template>查询
            </a-button>
          </a-col>
        </a-row>
      </a-form>
    </template>
    <template #toolbarLeft>
      <a-form-item label="总数">
        <a-tag color="red">{{totalNum}}条</a-tag>
      </a-form-item>
    </template>
    <template #toolbarRight>
      <a-button @click="exportData">
        导出Excel
      </a-button>
    </template>
    <a-table :columns="initColumns" :scroll="{ x: 2500, y: 520}" :row-key="record => record.id" :data-source="listData" :loading="loading" :pagination="pagination" :rowKey="(record,index)=>{return index}" @change="handleChange">
      <template #fileId="{ record }">
        <span v-if="record.fileId !== null">已交车</span>
        <span class="d89f8" v-if="record.fileId === null">未交车</span>
        <span v-if="record.deliveryOfficeOrgName !== null && record.deliveryOfficeOrgName !== ''">{{record.deliveryOfficeOrgName}}</span>
      </template>
    </a-table>
  </suy-table>
</template>
<script>

import { stockInPage, stockInPageExport } from '@/api/dataCenter/stock'
import { toRefs, reactive, onMounted } from 'vue'
import { SearchOutlined } from '@ant-design/icons-vue'
import SuyTable from '@/components/SuyTable'
export default {
  props: {
    cityDetail: String,
    type: { type: Number, default: null },
    cityIdDetail: { type: Number, default: null }
  },
  setup (props) {
    const state = reactive({
      formRef: null,
      loading: false,
      placeholder: props.cityDetail,
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      searchForm: {
        type: 1,
        rowNum: 1,
        vehicleTransStatus: props.type,
        cityId: props.cityIdDetail
      },
      totalNum: 0,
      form: {
        quota: '',
        orgId: '',
        num: 0,
        type: 1
      },
      listData: [],
      initColumns: [],
      columns: [
        {
          title: '发运时间',
          dataIndex: 'pushTime',
          key: 'pushTime'
        },
        {
          title: '卸车人',
          dataIndex: 'unloadingOperator',
          key: 'unloadingOperator'
        },
        {
          title: '卸车人部门',
          dataIndex: 'unloadingOrgName',
          key: 'unloadingOrgName'
        },
        {
          title: '订单ID',
          dataIndex: 'orderId',
          key: 'orderId'
        },
        {
          title: '车辆型号',
          dataIndex: 'model',
          key: 'model'
        },
        {
          title: '车架号',
          dataIndex: 'vinNo',
          key: 'vinNo'
        },
        {
          scopedSlots: {
            title: 'title1'
          }
        },
        {
          title: '城市',
          dataIndex: 'nowCity',
          key: 'nowCity'
        },
        {
          title: '车辆当前状态',
          dataIndex: 'vehicleTransStatus.label',
          key: 'vehicleTransStatus.label'
        },
        {
          title: '是否送车',
          dataIndex: 'isTakeOut.label',
          key: 'isTakeOut.label'
        },
        {
          title: '区域来源',
          dataIndex: 'officeOrgName',
          key: 'officeOrgName'
        },
        {
          title: '接单人员',
          dataIndex: 'salesman',
          key: 'salesman'
        },
        {
          title: '始发地',
          dataIndex: 'startAddress',
          key: 'startAddress'
        },
        {
          title: '历史中转地',
          dataIndex: 'transitAddress',
          key: 'transitAddress'
        },
        {
          title: '目的地',
          dataIndex: 'endAddress',
          key: 'endAddress'
        },
        {
          title: '运输类型',
          dataIndex: 'transportType.label',
          key: 'transportType.label'
        },
        {
          title: '到收金额',
          dataIndex: 'paidAmt',
          key: 'paidAmt'
        },
        {
          title: '取车费用',
          dataIndex: 'takeOutFee',
          key: 'takeOutFee'
        },
        {
          title: '收车人',
          dataIndex: 'picker',
          key: 'picker'
        },
        {
          title: '收车人电话',
          dataIndex: 'pickerMobile',
          key: 'pickerMobile'
        },
        {
          title: '结算方式',
          dataIndex: 'orderSettlement.label',
          key: 'orderSettlement.label'
        },
        {
          title: '结算状态',
          dataIndex: 'settlementStatus.label',
          key: 'settlementStatus.label'
        }
      ]
    })

    // console.log('props.cityIdDetail', props.cityIdDetail)
    // console.log('props.placeholdcityDetailer', props.cityDetail)
    // console.log('props.placeholder', state.placeholder)
    const searchBtn = () => {
      state.pagination.current = 1
      loadData()
    }
    const loadData = async () => {
      state.loading = true
      state.initColumns = state.columns
      // console.log('state.searchForm', state.searchForm)
      stockInPage({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.size
      }).then((res) => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.totalNum = res.data.total
          state.pagination.current = res.data.current
          state.pagination.pageSize = res.data.size
          state.pagination.total = res.data.total
        }
      }).finally(() => {
        state.loading = false
      })
    }
    const handleChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }

    const exportData = () => {
      stockInPageExport({ ...state.searchForm }, '库存统计-明细').then(res => {
      })
    }
    const setEndAddress = (value, selectedOptions) => {
      state.searchForm.provinceId = value[0]
      state.searchForm.cityId = value[1]
    }
    const detailForm = (searchForm) => {
      // state.searchForm = searchForm
      loadData()
    }
    onMounted(loadData)
    return {
      detailForm,
      ...toRefs(state),
      loadData,
      searchBtn,
      setEndAddress,
      exportData,
      handleChange
    }
  },
  components: {
    SearchOutlined,
    SuyTable
  }
}
</script>
